import React, { useEffect, useState, useCallback } from 'react';
import { Button, Col, Row, ListGroup, Container, Card, Accordion, Badge } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

const WebcamComponent = () => {
    const [usingFrontCamera, setUsingFrontCamera] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [messages, setMessages] = useState([]);
    const [capturedImage, setCapturedImage] = useState(null);
    const location = useLocation();
    const [tag, setTag] = useState(null);

    // Update the IMAGE_PROCESSING_URL to the new endpoint
    // const IMAGE_PROCESSING_URL = 'https://instavision-testing.partypics.zip/process_image'; // STAGING @ RR-SPACEBOOK
    // const IMAGE_PROCESSING_URL = 'https://instavision.partypics.zip/process_image'; // PROD @ RR-AWS

    // const IMAGE_PROCESSING_URL = 'https://fakestream-testing.chaturfake.com/process_image'; // STAGING @ RR-SPACEBOOK
    const IMAGE_PROCESSING_URL = 'https://fakestream.chaturfake.com/process_image'; // PROD @ RR-AWS

    useEffect(() => {
        initializeWebcam();
        if (new URLSearchParams(location.search).get('auto') === 'yessir') {
            automateCapture();
        }
    }, [location.search]);

    const initializeWebcam = useCallback(() => {
        const video = document.getElementById('webcam');
        navigator.mediaDevices.getUserMedia({ 
            video: { 
                facingMode: 'user', 
                width: { ideal: 4096 }, 
                height: { ideal: 2160 },
                aspectRatio: 1 / 1
            } 
        })
        .then(stream => {
            video.srcObject = stream;
        })
        .catch(error => console.error('getUserMedia error:', error));
    }, []);

    const captureImage = useCallback(() => {
        const video = document.getElementById('webcam');
        const canvas = document.getElementById('canvas');
        const context = canvas.getContext('2d');
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        const base64Image = canvas.toDataURL('image/jpeg');
        setCapturedImage(base64Image);
        processImage(base64Image.split(',')[1]);
    }, []);

    const processImage = useCallback((base64Image) => {
        toggleLoader(true);
        fetch(IMAGE_PROCESSING_URL, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ image: base64Image })
        })
        .then(response => {
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            return response.json();
        })
        .then(data => handleResponse(data))
        .catch(error => handleError(error));
    }, []);

    const toggleLoader = (show) => {
        setIsLoading(show);
    };

    const handleResponse = (data) => {
        toggleLoader(false);
        if (data.error) {
            console.error(data.error);
            appendToChatbox(`Error: ${data.error}`, true);
            return;
        }
        appendToChatbox(data.choices[0].message.content);
    };

    const handleError = (error) => {
        toggleLoader(false);
        console.error('Fetch error:', error);
        appendToChatbox(`Error: ${error.message}`, true);
    };

    const appendToChatbox = (message, isUserMessage = false) => {
        setMessages(prevMessages => [
            { message, isUserMessage, timestamp: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) },
            ...prevMessages
        ]);
        setTag(generateGamerTag()); // Generate a new tag for each message
    };

    const switchCamera = useCallback(() => {
        setUsingFrontCamera(!usingFrontCamera);
        const constraints = { video: { facingMode: (usingFrontCamera ? 'environment' : 'user') } };
        const video = document.getElementById('webcam');

        if (video.srcObject) {
            video.srcObject.getTracks().forEach(track => track.stop());
        }

        navigator.mediaDevices.getUserMedia(constraints)
            .then(stream => {
                video.srcObject = stream;
            })
            .catch(error => console.error('Error accessing media devices.', error));
    }, [usingFrontCamera]);

    const automateCapture = () => {
        // const interval = Math.floor(Math.random() * (7500 - 3000 + 1)) + 2000;
        const interval = 6550;
        setTimeout(() => {
            captureImage();
            automateCapture();
        }, interval);
    };

    const columns = [
        ["Apple", "Iron", "Gold", "Silver", "Stone", "Water", "Glass", "Steel", "Wood", "Leather", "Paper", "Plastic", "Cotton", "Silk", "Wool", "Diamond", "Crystal", "Velvet", "Rubber", "Marble", "Brick", "Lace", "Denim", "Satin", "Cashmere"],
        ["Engineer", "Teacher", "Nurse", "Doctor", "Lawyer", "Accountant", "Chef", "Programmer", "Mechanic", "Artist", "Salesperson", "Electrician", "Plumber", "Pharmacist", "Firefighter", "Police", "Manager", "Architect", "Bartender", "Librarian", "Journalist", "Realtor", "Photographer", "Pilot", "Dentist"],
        ["Apple", "Banana", "Orange", "Kiwi", "Grape", "Mango", "Lemon", "Lime", "Peach", "Pear", "Pineapple", "Strawberry", "Cherry", "Watermelon", "Raspberry", "Blueberry", "Pomegranate", "Avocado", "Cucumber", "Tomato", "Carrot", "Potato", "Onion", "Broccoli", "Spinach"],
        ["Shadow", "Ninja", "Killer", "Dragon", "Wolf", "Fire", "Ice", "Dark", "Light", "King", "Ghost", "Phantom", "Thunder", "Storm", "Blaze", "Rogue", "Mystic", "Savage", "Rebel", "Fury", "Sniper", "Beast", "Vortex", "Eclipse", "Viper"]
    ];
    
    function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
    }
    
    const generateGamerTag = () => {
        // Shuffle columns array to get a random order
        shuffleArray(columns);
    
        // Select the first two columns after shuffle
        const selectedColumns = columns.slice(0, 2);
    
        // Shuffle each selected column to randomize word selection
        selectedColumns.forEach(shuffleArray);
    
        // Select the first word from each shuffled column
        const word1 = selectedColumns[0][0];
        const word2 = selectedColumns[1][0];
    
        // const randomNumber = Math.floor(Math.random() * (3.1496) + 10); // 2-3 digit number
        const randomNumber = ""; // blank
        const tagParts = [word1, word2];
    
        // Optionally shuffle tagParts if needed
        // shuffleArray(tagParts);
    
        return `${tagParts.join('')}${randomNumber}`;
    };

    return (
        <>
        <Container fluid className="py-2 bg-gradient h-100">
            <Row>            
                <Col xs={12} md={12} className="Xmx-auto">
                    <Row className="d-none">
                        <Col xs={6} md={6} className="text-left">
                            <h1 className="text-black my-2"><span className="text-info my-3">Chat</span><span className="text-danger my-3">u</span><span className="text-warning my-3">r</span><span className="text-success my-3">Fake</span></h1>
                        </Col>
                        {/* <Col xs={12} md={7} className="text-center">
                            <p className="text-light my-1 fw-bold my-2 d-none">Start your own FakeStream by pressing the button below!</p>
                        </Col> */}
                        <Col xs={6} md={6} className="text-right">
                            <i className="fa-duotone fa-burger fs-1 mb-3 text-warning my-2" alt="hamburger menu" title=""></i>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={2}>
                            <Row className="Xd-none">
                                <Col xs={10} md={10} className="text-left">
                                    <h1 className="text-black my-2"><span className="text-info my-3">Chat</span><span className="text-danger my-3">u</span><span className="text-warning my-3">r</span><span className="text-success my-3">Fake</span></h1>
                                </Col>
                                {/* <Col xs={12} md={7} className="text-center">
                                    <p className="text-light my-1 fw-bold my-2 d-none">Start your own FakeStream by pressing the button below!</p>
                                </Col> */}
                                <Col xs={2} md={2} className="text-right">
                                    <i className="fa-sharp fa-light fa-face-lying fs-1 mb-3 text-light my-2" alt="hamburger menu" title=""></i>
                                </Col>
                            </Row>
                            <Row className="d-none d-md-block">
                                <Col>
                                    <div className="justify-content-between my-1" style={{ textAlign: 'justify' }}>
                                        Join ChatURFake for a journey into the hilarious side of streaming. It's all about fun, satire, and a unique take on the streaming culture. Discover more inside!
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mb-1 d-none d-md-flex">
                                <Col xs={6} md={6} >
                                    <Button variant="success" className="w-100 text-center shadow-sm btn btn-success my-2 mx-0" href="/?auto=yessir">Start</Button>
                                </Col>
                                <Col xs={6} md={6}>
                                    <Button variant="danger" className="w-100 text-center shadow-sm btn btn-danger my-2 mx-0" href="/">Stop</Button>
                                </Col>
                                
                                <Col xs={6} md={4} className="d-none">
                                    <Button variant="info" className="w-100 text-center shadow-sm btn btn-info my-2 px-1" onClick={switchCamera}>Change Camera</Button>
                                </Col>
                            </Row>
                            <Row className="d-none d-md-block">
                                <Col>
                                    <Accordion defaultActiveKey="0">
                                        <Card>
                                            <Accordion.Toggle as={Card.Header} eventKey="0" className="bg-dark bg-muted bg-gradient text-light">
                                            How do I use this thing?
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="0">
                                            <Card.Body style={{ textAlign: 'justify' }}>
                                                Press the green <Badge variant="success">Start</Badge> button and smile for the camera, you're "live"! Once you're "streaming", the comments start to pour in from your viewers. When you can't take the heat, press the red <Badge variant="danger">Stop</Badge> button.
                                            </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        <Card>
                                            <Accordion.Toggle as={Card.Header} eventKey="1" className="bg-dark bg-muted bg-gradient text-light">
                                            What is the purpose of this?
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="1">
                                            <Card.Body style={{ textAlign: 'justify' }}>
                                                Recently, OpenAI upgraded GPT-4 with <Badge variant="info">VISION</Badge> which allows submitting images with prompts. In our case, a custom prompt was crafted to present a "real-life" (fake) streaming experience.
                                            </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                        <Card>
                                            <Accordion.Toggle as={Card.Header} eventKey="2" className="bg-dark bg-muted bg-gradient text-light">
                                            Where do we go from here?
                                            </Accordion.Toggle>
                                            <Accordion.Collapse eventKey="2">
                                            <Card.Body style={{ textAlign: 'justify' }}>
                                                This is just the start. Check back often for more features, designs, and more.
                                            </Card.Body>
                                            </Accordion.Collapse>
                                        </Card>
                                    </Accordion>
                                </Col>
                            </Row>
                            <Row className="d-none d-md-block my-2">
                                <Col xs={12} md={12} className="Xd-none">
                                    <Button variant="warning" className="w-100 text-center shadow-sm btn btn-warning my-2 px-1" onClick={captureImage}>Capture Image</Button>
                                </Col>
                            </Row>
                        </Col>
                        <Col xs={12} md={10} style={{ overflow: 'hidden' }}>
                            <video autoPlay playsInline width="100%" height="auto" id="webcam" className="rounded shadow bg-black"></video>
                            <canvas style={{ display: 'none' }} id="canvas"></canvas>
                            <Card className="bg-transparent text-white mt-2" id="stream-messages" style={{overflow: 'hidden' , position: 'absolute', top: '0', right: '1em', border: '0 solid red', minWidth: '15vw', maxWidth: '30vw'}}>
                                <Card.Body className="bg-transparent text-white rounded" style={{ overflowY: 'hidden' }}>             
                                    <ListGroup variant="flush" id="stream-greeting">
                                        <ListGroup.Item className="bg-info bg-gradient shadow-sm text-dark border0 rounded mb-2" style={{ border: 'unset' }}>
                                            <h5 className="mb-1 text-light"><strong>@UR_1LonelyStreamer</strong></h5>
                                            <p className="mb-1">What is everyone up to? #bored #streamer #pointless</p>
                                            <small className="text-warning fw-bold">LIVE NOW!</small>
                                        </ListGroup.Item>
                                    </ListGroup>
                                    {isLoading && <div className="loader"></div>}
                                    <ListGroup variant="flush" id="stream-reply">
                                        {messages.map((msg, index) => (
                                        <ListGroup.Item key={index} className={msg.isUserMessage ? 'user-message shadow-sm bg-light bg-muted text-dark rounded my-2' : 'assistant-message shadow-sm bg-light bg-muted text-dark rounded my-2' } style={{ border: 'unset' }}>
                                                <h5 className="mb-1 d-none"><strong>@{tag}</strong></h5>
                                                <p className="mb-1">{msg.message}</p>
                                                <small className="">{msg.timestamp}</small>                            
                                        </ListGroup.Item>
                                        ))}
                                    </ListGroup>                            
                                </Card.Body>
                            </Card>
                            <div>
                                <div id="stream-gif" style={{overflow: 'scroll', width: '16vw', height: '17vh', aspectRaitio: '1', position: 'absolute', bottom: '1.25em', left: '1.25em', border: '2 solid white', maxWidth: '25vw', borderRadius: '12px'}}>
                                    <h5 className="text-warning" id="stream-tag" style={{ textShadow: '1px 2px #2b2b2b' }}>@L0n3lyStr34m3r42069</h5>
                                    <iframe src="https://giphy.com/embed/9ok7NGU37j5Pq" width="100%" height="100%" style={{position: 'absolute', borderRadius: '12px'}} frameBorder={0} className="giphy-embed shadow-sm" />
                                </div>
                            </div>
                            <div id="stream-social" style={{overflow: 'hidden' , position: 'absolute', top: '0.5em', left: '1.5em', border: '0 solid red', maxWidth: '17vw'}}>
                                <Row>
                                    <Col><i className="fa-duotone fa-share-from-square fs-1 mb-3 text-light"></i></Col>
                                </Row>
                                <Row>
                                    <Col><i className="fa-brands fa-facebook fs-1 mb-3 text-light"></i></Col>
                                </Row>
                                <Row>
                                    <Col><i className="fa-brands fa-twitter fs-1 mb-2 text-light"></i></Col>
                                </Row>
                                <Row>
                                    <Col>&nbsp;</Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    <Row className="mb-1 d-md-none">
                        <Col xs={6} md={6} >
                            <Button variant="success" className="w-100 text-center shadow-sm btn btn-success my-2 px-1" href="/?auto=yessir">Start</Button>
                        </Col>
                        <Col xs={6} md={6}>
                            <Button variant="danger" className="w-100 text-center shadow-sm btn btn-danger my-2 px-1" href="/">Stop</Button>
                        </Col>
                        <Col xs={12} md={4} className="Xd-none">
                            <Button variant="warning" className="w-100 text-center shadow-sm btn btn-warning my-2 px-1" onClick={captureImage}>Capture Image</Button>
                        </Col>
                        <Col xs={6} md={4} className="d-none">
                            <Button variant="info" className="w-100 text-center shadow-sm btn btn-info my-2 px-1" onClick={switchCamera}>Change Camera</Button>
                        </Col>
                    </Row>
                    <Row>
                        <Card className="bg-transparent text-white mt-2 d-block d-sm-none shadow-sm" id="stream-messages-mobile" style={{ border: '0 solid red' }}>
                            <Card.Header className="text-center bg-info shadow-sm">
                                The Comment Section
                            </Card.Header>
                            <Card.Body className="bg-light bg-muted bg-gradient text-white shadow-sm" style={{ overflowY: 'scroll', maxHeight: '480px' }}>             
                                {isLoading && <div className="loader"></div>}
                                <ListGroup variant="flush" id="stream-reply">
                                    {messages.map((msg, index) => (
                                    <ListGroup.Item key={index} className={msg.isUserMessage ? 'user-message shadow-sm bg-info bg-gradient bg-muted text-dark rounded my-2' : 'assistant-message shadow-sm bg-info bg-gradient bg-muted text-dark rounded my-2' } style={{ border: 'unset' }}>
                                            <h5 className="mb-1 d-none"><strong>@{tag}</strong></h5>
                                            <p className="mb-1">{msg.message}</p>
                                            <small className="">{msg.timestamp}</small>                            
                                    </ListGroup.Item>
                                    ))}
                                </ListGroup>                            
                            </Card.Body>
                            <Card.Footer className="bg-info bg-muted bg-gradient text-center text-muted fw-bold">
                                <span>Webcam is local on your browser. No images are saved or stored. Enjoy!</span>
                            </Card.Footer>
                        </Card>
                    </Row>
                    <Row className="d-md-none my-2">
                        <Col>
                            <Accordion defaultActiveKey="0">
                                <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey="0" className="bg-dark bg-muted bg-gradient text-light">
                                    How do I use this thing?
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="0">
                                    <Card.Body style={{ textAlign: 'justify' }}>
                                    Press the green <Badge variant="success">Start</Badge> button and smile for the camera, you're "live"! Once you're "streaming", the comments start to pour in from your viewers. When you can't take the heat, press the red <Badge variant="danger">Stop</Badge> button.
                                    </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey="1" className="bg-dark bg-muted bg-gradient text-light">
                                    What is the purpose of this?
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="1">
                                    <Card.Body style={{ textAlign: 'justify' }}>
                                        Recently, OpenAI upgraded GPT-4 with <Badge variant="info">VISION</Badge> which allows submitting images with prompts. In our case, a custom prompt was crafted to present a "real-life" (fake) streaming experience.
                                    </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                                <Card>
                                    <Accordion.Toggle as={Card.Header} eventKey="2" className="bg-dark bg-muted bg-gradient text-light">
                                    Where do we go from here?
                                    </Accordion.Toggle>
                                    <Accordion.Collapse eventKey="2">
                                    <Card.Body style={{ textAlign: 'justify' }}>
                                        This is just the start. Check back often for more features, designs, and more.
                                    </Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            </Accordion>
                        </Col>
                    </Row>
                    <Row className="text-center my-1">
                        <Col xs={12}>
                            &copy; RedRadar Development 2023
                        </Col>
                    </Row>             
                </Col>
            </Row>
        </Container>
        </>
    );
};

export default WebcamComponent;